import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

export const orReportsApi = createApi({
  reducerPath: 'orReportsApi',
  baseQuery: btBaseQuery,

  endpoints: (builder) => ({
    // Fetch all orders by date
    findAllOrdersByDate: builder.query<
      any[],
      { cid: string; fdt: string; tdt: string }
    >({
      query: ({ cid, fdt, tdt }) => ({
        url: `reports/orders/${cid}/${fdt}/${tdt}`
      })
    }),
    findPendPack: builder.query<
      any[],
      { cid: string; fdt: string; tdt: string }
    >({
      query: ({ cid, fdt, tdt }) => ({
        url: `reports/pend/pack/${cid}/${fdt}/${tdt}`
      })
    }),
    findPendCutt: builder.query<
      any[],
      { cid: string; fdt: string; tdt: string }
    >({
      query: ({ cid, fdt, tdt }) => ({
        url: `reports/pend/cutt/${cid}/${fdt}/${tdt}`
      })
    }),
    findPendStitch: builder.query<
      any[],
      { cid: string; fdt: string; tdt: string }
    >({
      query: ({ cid, fdt, tdt }) => ({
        url: `reports/pend/stitch/${cid}/${fdt}/${tdt}`
      })
    }),
    findStock: builder.query<any[], string>({
      query: (cid) => ({
        url: `reports/stock/${cid}`
      })
    })
  })
});

export const {
  useFindAllOrdersByDateQuery,
  useFindPendCuttQuery,
  useFindPendStitchQuery,
  useFindPendPackQuery,
  useFindStockQuery
} = orReportsApi;
