import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

// Define API for Group routes with `stm` prefix
export const stmgroupApi = createApi({
  reducerPath: 'stmgroupApi',
  baseQuery: btBaseQuery,
  tagTypes: ['Group'],
  endpoints: (builder) => ({
    getStmGroups: builder.query<any[], string>({
      query: (companyId) => `/stmgroup/${companyId}`,
      providesTags: ['Group']
    }),
    getStmGroupById: builder.query<any, string>({
      query: (id) => `/stmgroup/byid/${id}`,
      providesTags: ['Group']
    }),
    insertStmGroup: builder.mutation({
      query: (group) => ({
        url: '/stmgroup',
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['Group']
    }),
    updateStmGroup: builder.mutation({
      query: (group) => ({
        url: `/stmgroup`,
        method: 'PUT',
        body: group
      }),
      invalidatesTags: ['Group']
    }),
    deleteStmGroup: builder.mutation({
      query: (id) => ({
        url: `/stmgroup/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Group']
    })
  })
});

// Export hooks for usage in functional components
export const {
  useGetStmGroupsQuery,
  useGetStmGroupByIdQuery,
  useInsertStmGroupMutation,
  useUpdateStmGroupMutation,
  useDeleteStmGroupMutation
} = stmgroupApi;
