import { Button, Paper } from '@mui/material';
import React, { useState } from 'react';
import Webcam from 'react-webcam';

const WebcamComponent = () => <Webcam />;

const videoConstraints = {
  width: 88,
  height: 80,
  facingMode: 'user'
};

export const WebcamCapture = () => {
  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  return (
    <div className="webcam-container">
      <div className="webcam-img">
        {image == '' ? (
          <Webcam
            audio={false}
            height={50}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={55}
            videoConstraints={videoConstraints}
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
          />
        ) : (
          <Paper variant="outlined">
            <img
              src={image}
              alt="No Image"
              onClick={(e) => {
                e.preventDefault();
                setImage('');
              }}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};
