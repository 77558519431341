import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

export const ormConsumableApi = createApi({
  reducerPath: 'ormConsumableApi',
  baseQuery: btBaseQuery,
  tagTypes: ['Consumable'],
  endpoints: (builder) => ({
    findAllConsumables: builder.query<any[], string>({
      query: (company) => `consumables/${company}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Consumable', id } as const)),
              { type: 'Consumable', id: 'LIST' }
            ]
          : [{ type: 'Consumable', id: 'LIST' }]
    }),
    findConsumableById: builder.query<any, string>({
      query: (id) => `consumables/byid/${id}`,
      providesTags: (result, error, id) => [{ type: 'Consumable', id }]
    }),
    insertConsumable: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: 'consumables',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Consumable', id: 'LIST' }]
    }),
    updateConsumable: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: 'consumables',
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Consumable', id }]
    }),
    deleteConsumable: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query: (id) => ({
        url: `consumables/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Consumable', id: 'LIST' }]
    })
  })
});

export const {
  useFindAllConsumablesQuery,
  useFindConsumableByIdQuery,
  useInsertConsumableMutation,
  useUpdateConsumableMutation,
  useDeleteConsumableMutation
} = ormConsumableApi;
