import LoginImg from './logo/logo.png';
import Bluetech from './logo/bluetech.png';

export function BTLoginImg() {
  return <img src={LoginImg} alt="BlueTech" />;
}

export function BluetechLogo() {
  return <img src={Bluetech} alt="BlueTech" />;
}

export default BTLoginImg;
