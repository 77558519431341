import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

// Define API for Group routes with `stm` prefix
export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: btBaseQuery,

  endpoints: (builder) => ({
    getdashboards: builder.query<any[], string>({
      query: (companyId) => `/dashboard/${companyId}`
    })
  })
});

// Export hooks for usage in functional components
export const { useGetdashboardsQuery } = dashboardApi;
