import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../../redux/services/authSlice";

export default function Logout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(resetState());
		navigate("/");
	}, [dispatch, navigate]);

	return null;
}
