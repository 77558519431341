import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { WebcamCapture } from './screens/pages/Transaction/STPurchaseEntry/cam';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AdminCompany = Loader(
  lazy(() => import('src/screens/pages/Admin/Company'))
);
const AdminUsers = Loader(lazy(() => import('src/screens/pages/Admin/Users')));
const AdminCountry = Loader(
  lazy(() => import('src/screens/pages/Admin/Country'))
);
const AdminState = Loader(lazy(() => import('src/screens/pages/Admin/State')));
const AdminModes = Loader(lazy(() => import('src/screens/pages/Admin/Mode')));
const AdminTaxGroup = Loader(
  lazy(() => import('src/screens/pages/Admin/TaxGroup'))
);
const AdminTax = Loader(lazy(() => import('src/screens/pages/Admin/Tax')));
const AdminUom = Loader(lazy(() => import('src/screens/pages/Admin/Uom')));

const Department = Loader(
  lazy(() => import('src/screens/pages/Masters/Department'))
);
const MastersEmployee = Loader(
  lazy(() => import('src/screens/pages/Masters/Employee'))
);
const EMAttendance = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMAttendance'))
);
const EMPayRoll = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMPayRoll'))
);
const EMWages = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMWages'))
);
const EMAttLog = Loader(
  lazy(() => import('src/screens/pages/Reports/EMAttLog'))
);
const EMPayRollRep = Loader(
  lazy(() => import('src/screens/pages/Reports/EMPayRoll'))
);
const EMWagesRep = Loader(
  lazy(() => import('src/screens/pages/Reports/EMWages'))
);

const ACHead = Loader(lazy(() => import('src/screens/pages/Masters/ACHead')));
const ACExpenses = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACExpenses'))
);
const ACIncomes = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACIncomes'))
);
const ACExpensesRep = Loader(
  lazy(() => import('src/screens/pages/Reports/ACExpenses'))
);
const ACIncomesRep = Loader(
  lazy(() => import('src/screens/pages/Reports/ACIncomes'))
);

const MastersSupplier = Loader(
  lazy(() => import('src/screens/pages/Masters/Supplier'))
);
const STGroupMaster = Loader(
  lazy(() => import('src/screens/pages/Masters/STGroupMaster'))
);
const STProduct = Loader(
  lazy(() => import('src/screens/pages/Masters/STProduct'))
);

const STPurchaseEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/STPurchaseEntry'))
);
const STIssueEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/STIssueEntry'))
);

const StockReport = Loader(
  lazy(() => import('src/screens/pages/Reports/StockReport'))
);
const PurchaseReport = Loader(
  lazy(() => import('src/screens/pages/Reports/PurchaseReport'))
);
const IssueReport = Loader(
  lazy(() => import('src/screens/pages/Reports/IssueReport'))
);
const IssuePendingReport = Loader(
  lazy(() => import('src/screens/pages/Reports/IssuePendingReport'))
);

const CustomerMaster = Loader(
  lazy(() => import('src/screens/pages/Masters/CustomerMaster'))
);
const GarmentTypeForm = Loader(
  lazy(() => import('src/screens/pages/Masters/GarmentTypeForm'))
);
const PatternMaster = Loader(
  lazy(() => import('src/screens/pages/Masters/PatternMaster'))
);
const ConsumableMaster = Loader(
  lazy(() => import('src/screens/pages/Masters/ConsumableMaster'))
);

const ORBookingEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/ORBookingEntry'))
);
const ORCuttingEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/ORCuttingEntry'))
);
const ORStitchingEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/ORStitchingEntry'))
);
const ORPackingEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/ORPackingEntry'))
);
const ORSalesEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/ORSalesEntry'))
);

const OrderReport = Loader(
  lazy(() => import('src/screens/pages/Reports/OrderReport'))
);
const PendPackReport = Loader(
  lazy(() => import('src/screens/pages/Reports/PendPackReport'))
);
const PendCuttReport = Loader(
  lazy(() => import('src/screens/pages/Reports/PendCuttReport'))
);
const PendStitchReport = Loader(
  lazy(() => import('src/screens/pages/Reports/PendStitchReport'))
);
const StockFGReport = Loader(
  lazy(() => import('src/screens/pages/Reports/StockFGReport'))
);

// Pages
const Auth = Loader(lazy(() => import('src/screens/auth')));
// Dashboards
const Crypto = Loader(lazy(() => import('src/screens/dashboards/Crypto')));
const ChangePassword = Loader(
  lazy(() => import('src/screens/pages/Admin/ChangePassword'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/screens/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/screens/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/screens/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/screens/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: 'crypto',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'users',
        element: <AdminUsers />
      },
      {
        path: 'country',
        element: <AdminCountry />
      },
      {
        path: 'state',
        element: <AdminState />
      },
      {
        path: 'mode',
        element: <AdminModes />
      },
      {
        path: 'taxgroup',
        element: <AdminTaxGroup />
      },
      {
        path: 'tax',
        element: <AdminTax />
      },
      {
        path: 'uom',
        element: <AdminUom />
      },
      {
        path: 'changepwd',
        element: <ChangePassword />
      }
    ]
  },
  {
    path: 'master',
    element: <SidebarLayout />,
    children: [
      {
        path: 'suppliermaster',
        element: <MastersSupplier />
      },
      {
        path: 'departmentmaster',
        element: <Department />
      },
      {
        path: 'employeemaster',
        element: <MastersEmployee />
      },
      {
        path: 'accountshead',
        element: <ACHead />
      },
      {
        path: 'productgroupmaster',
        element: <STGroupMaster />
      },
      {
        path: 'productmaster',
        element: <STProduct />
      },
      {
        path: 'garmenttypemaster',
        element: <GarmentTypeForm />
      },
      {
        path: 'patternmaster',
        element: <PatternMaster />
      },
      {
        path: 'consumablesmaster',
        element: <ConsumableMaster />
      },
      {
        path: 'customermaster',
        element: <CustomerMaster />
      }
    ]
  },
  {
    path: 'entry',
    element: <SidebarLayout />,
    children: [
      {
        path: 'purchaseentry',
        element: <STPurchaseEntry />
      },
      {
        path: 'purchaseentry/:id',
        element: <STPurchaseEntry />
      },
      {
        path: 'bookingentry',
        element: <ORBookingEntry />
      },
      {
        path: 'bookingentry/:id',
        element: <ORBookingEntry />
      },
      {
        path: 'cuttingentry',
        element: <ORCuttingEntry />
      },
      {
        path: 'cuttingentry/:id',
        element: <ORCuttingEntry />
      },
      {
        path: 'stitchingentry',
        element: <ORStitchingEntry />
      },
      {
        path: 'stitchingentry/:id',
        element: <ORStitchingEntry />
      },
      {
        path: 'packingentry',
        element: <ORPackingEntry />
      },
      {
        path: 'packingentry/:id',
        element: <ORPackingEntry />
      },
      {
        path: 'salesentry',
        element: <ORSalesEntry />
      },
      {
        path: 'salesentry/:id',
        element: <ORSalesEntry />
      },
      {
        path: 'webcam',
        element: <WebcamCapture />
      },
      {
        path: 'issueentry',
        element: <STIssueEntry />
      },
      {
        path: 'issueentry/:id',
        element: <STIssueEntry />
      },
      {
        path: 'expensesentry',
        element: <ACExpenses />
      },
      {
        path: 'incomeentry',
        element: <ACIncomes />
      },
      {
        path: 'attendanceentry',
        element: <EMAttendance />
      },
      {
        path: 'payrollentry',
        element: <EMPayRoll />
      },
      {
        path: 'wagesentry',
        element: <EMWages />
      }
    ]
  },
  {
    path: 'reports',
    element: <SidebarLayout />,
    children: [
      {
        path: 'datewisebooking',
        element: <OrderReport />
      },
      {
        path: 'datewisepurchases',
        element: <PurchaseReport />
      },
      {
        path: 'datewiseissues',
        element: <IssueReport />
      },
      {
        path: 'issuepending',
        element: <IssuePendingReport />
      },
      {
        path: 'cuttingpending',
        element: <PendCuttReport />
      },
      {
        path: 'stitchingpending',
        element: <PendStitchReport />
      },
      {
        path: 'packingpending',
        element: <PendPackReport />
      },
      {
        path: 'stock',
        element: <StockFGReport />
      },
      {
        path: 'stockledger',
        element: <StockReport />
      },
      {
        path: 'expenses',
        element: <ACExpensesRep />
      },
      {
        path: 'incomes',
        element: <ACIncomesRep />
      },
      {
        path: 'attendance',
        element: <EMAttLog />
      },
      {
        path: 'payroll',
        element: <EMPayRollRep />
      },
      {
        path: 'wages',
        element: <EMWagesRep />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

const authroutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Auth />
      },
      {
        path: 'company',
        element: <AdminCompany />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

export default routes;
export { authroutes };
