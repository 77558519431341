import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

export const oreOrderApi = createApi({
  reducerPath: 'oreOrderApi',
  baseQuery: btBaseQuery,
  tagTypes: ['Order'],

  endpoints: (builder) => ({
    findAllOrders: builder.query({
      query: (company) => `order/${company}`,
      providesTags: ['Order']
    }),
    findPendingOrders: builder.query({
      query: ({ cid, id }) => `order/pend/${cid}/${id}`,
      providesTags: ['Order']
    }),
    findPendingPackingOrders: builder.query({
      query: ({ cid, id }) => `order/pend/pack/${cid}/${id}`,
      providesTags: ['Order']
    }),
    findPendingCuttingOrders: builder.query({
      query: ({ cid, id }) => `order/pend/cut/${cid}/${id}`,
      providesTags: ['Order']
    }),
    findPendingStitchingOrders: builder.query({
      query: ({ cid, id }) => `order/pend/stitch/${cid}/${id}`,
      providesTags: ['Order']
    }),
    findOrderById: builder.query({
      query: (id) => `order/byid/${id}`,
      providesTags: ['Order']
    }),
    insertOrder: builder.mutation({
      query: (order) => ({
        url: 'order',
        method: 'POST',
        body: order
      }),
      invalidatesTags: ['Order']
    }),
    updateOrder: builder.mutation({
      query: (order) => ({
        url: 'order',
        method: 'PUT',
        body: order
      }),
      invalidatesTags: ['Order']
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `order/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Order']
    })
  })
});

export const {
  useFindAllOrdersQuery,
  useFindPendingOrdersQuery,
  useFindPendingPackingOrdersQuery,
  useFindPendingCuttingOrdersQuery,
  useFindPendingStitchingOrdersQuery,
  useFindOrderByIdQuery,
  useInsertOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation
} = oreOrderApi;
