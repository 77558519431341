import * as React from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog';

// Define the context value type correctly with `confirm` as a callable function
type ConfirmationServiceContextValue = {
  confirm: (options: ConfirmationOptions) => Promise<void>;
  resolve?: () => void;
  reject?: () => void;
};

const defaultConfirmationServiceContextValue: ConfirmationServiceContextValue =
  {
    confirm: () => {
      console.error('Confirmation function not initialized');
      return Promise.resolve(); // or Promise.reject(), depending on how you want to handle it
    },
    resolve: () => {
      console.error('Resolve function not initialized');
    },
    reject: () => {
      console.error('Reject function not initialized');
    }
  };

const ConfirmationServiceContext =
  React.createContext<ConfirmationServiceContextValue>(
    defaultConfirmationServiceContextValue
  );

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

type ConfirmationServiceProviderProps = {
  children: React.ReactNode;
};

export const ConfirmationServiceProvider: React.FC<
  ConfirmationServiceProviderProps
> = ({ children }) => {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  } | null>(null);

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const resolveConfirmation = () => {
    if (awaitingPromiseRef.current?.resolve) {
      awaitingPromiseRef.current.resolve();
      awaitingPromiseRef.current = null;
      setConfirmationState(null);
    }
  };

  const rejectConfirmation = () => {
    if (awaitingPromiseRef.current?.reject) {
      awaitingPromiseRef.current.reject();
      awaitingPromiseRef.current = null;
      setConfirmationState(null);
    }
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel) {
      rejectConfirmation();
    } else {
      setConfirmationState(null);
    }
  };

  const handleSubmit = () => {
    resolveConfirmation();
  };

  return (
    <ConfirmationServiceContext.Provider
      value={{
        confirm: openConfirmation,
        resolve: resolveConfirmation,
        reject: rejectConfirmation
      }}
    >
      {children}
      {confirmationState && (
        <ConfirmationDialog
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          resolve={resolveConfirmation}
          reject={rejectConfirmation}
          {...confirmationState}
        />
      )}
    </ConfirmationServiceContext.Provider>
  );
};
