import { Box, experimentalStyled, Typography } from "@mui/material";

export const AuthenticationWrapper = experimentalStyled(Box)(
	() => `
      overflow: auto;
      flex: 1;
      overflow-x: hidden;
      align-items: center;
  `
);

export const TypographyH2 = experimentalStyled(Typography)(
	({ theme }) => `
      font-size: ${theme.typography.pxToRem(17)};
  `
);
