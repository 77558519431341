import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { TypographyH2 } from 'src/screens/pages/Admin/Company/style';
import moment from 'moment';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {               
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const gdata = useSelector((state: RootState) => state.gdata);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref1 = useRef<any>(null);
  const [isOpen1, setOpen1] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleMenuItemClick = (): void => {
    setOpen(false);
  };

  const handleOpen1 = (): void => {
    setOpen1(true);
  };

  const handleClose1 = (): void => {
    setOpen1(false);
  };

  const handleMenuItemClick1 = (): void => {
    setOpen1(false);
  };

  return (
    <>
      <Grid sx={{ px: 10 }}>
        <TypographyH2 color={'#2aa752'} paddingTop={1}>
          {gdata.companyname}
        </TypographyH2>
      </Grid>
      <Grid sx={{ px: 10 }}>
        <TypographyH2 color={'#0078d4'} paddingTop={1} align="center">
          {moment().utcOffset('+05:30').format('DD/MM/YYYY')}
        </TypographyH2>
        <TypographyH2 color={'#2aa752'} paddingTop={1} align="center">
          {moment(currentTime).utcOffset('+05:30').format('HH:mm:ss')}
        </TypographyH2>
      </Grid>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/purchaseentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Purchase"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/bookingentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Bookings"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/issueentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Issue"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/cuttingentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Cutting"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/stitchingentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Stitching"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/packingentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Packing"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/entry/salesentry"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Sales"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={ref}
            onClick={handleOpen}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Reports
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={ref1}
            onClick={handleOpen1}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Pending
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </List>
      </ListWrapper>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/datewisebooking"
          onClick={handleMenuItemClick}
        >
          Booking
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/issue"
          onClick={handleMenuItemClick1}
        >
          Issue
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/cutting"
          onClick={handleMenuItemClick1}
        >
          Cutting
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/stitching"
          onClick={handleMenuItemClick1}
        >
          Stitching
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/packing"
          onClick={handleMenuItemClick1}
        >
          Packing
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/stockledger"
          onClick={handleMenuItemClick}
        >
          Stock Ledger
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/stock"
          onClick={handleMenuItemClick}
        >
          Stock
        </MenuItem>
      </Menu>
      <Menu anchorEl={ref1.current} onClose={handleClose1} open={isOpen1}>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/issuepending"
          onClick={handleMenuItemClick1}
        >
          Issue
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/cuttingpending"
          onClick={handleMenuItemClick1}
        >
          Cutting
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/stitchingpending"
          onClick={handleMenuItemClick1}
        >
          Stitching
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/reports/packingpending"
          onClick={handleMenuItemClick1}
        >
          Packing
        </MenuItem>
      </Menu>
    </>
  );
}

export default HeaderMenu;
