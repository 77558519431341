import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

export const ormPatternApi = createApi({
  reducerPath: 'ormPatternApi',
  baseQuery: btBaseQuery,
  tagTypes: ['Pattern'],
  endpoints: (builder) => ({
    findAllPatterns: builder.query<any[], string>({
      query: (company) => `pattern/${company}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Pattern', id } as const)),
              { type: 'Pattern', id: 'LIST' }
            ]
          : [{ type: 'Pattern', id: 'LIST' }]
    }),
    findPatternById: builder.query<any, string>({
      query: (id) => `pattern/byid/${id}`,
      providesTags: (result, error, id) => [{ type: 'Pattern', id }]
    }),
    insertPattern: builder.mutation<any, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (body[key] instanceof File) {
            formData.append(key, body[key]);
          } else {
            formData.append(key, body[key]);
          }
        });
        return {
          url: 'pattern',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: [{ type: 'Pattern', id: 'LIST' }]
    }),
    updatePattern: builder.mutation<any, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (body[key] instanceof File) {
            formData.append(key, body[key]);
          } else {
            formData.append(key, body[key]);
          }
        });
        return {
          url: 'pattern',
          method: 'PUT',
          body: formData
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Pattern', id }]
    }),
    deletePattern: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `pattern/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Pattern', id: 'LIST' }]
    })
  })
});

export const {
  useFindAllPatternsQuery,
  useFindPatternByIdQuery,
  useInsertPatternMutation,
  useUpdatePatternMutation,
  useDeletePatternMutation
} = ormPatternApi;
