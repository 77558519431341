import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LogicState {
  glogic: any;
}

const initialState: LogicState = {
  glogic: {}
};

export const logicSlice = createSlice({
  name: 'logic',
  initialState,
  reducers: {
    setLogic: (state, action) => {
      state.glogic = Object.assign(state.glogic, action.payload);
    },
    resetLogic: (state) => {
      state.glogic = {};
    },
    removeLogic: (state, action) => {
      let tstate = { ...state };
      delete tstate.glogic[action.payload.head];

      state.glogic = tstate.glogic;
    }
  }
});

export const { setLogic, resetLogic, removeLogic } = logicSlice.actions;
export const selectLogic = (state: RootState) => state.glogic;
export default logicSlice.reducer;
