import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: 'none' | 'danger' | 'info';
  title?: string;
  description?: string;
  htmlcomp?: React.ReactNode | (() => React.ReactNode);
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  resolve: () => void; // Added resolve
  reject: () => void; // Added reject
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  htmlcomp,
  onSubmit,
  onClose,
  resolve,
  reject
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description ? (
          <DialogContentText>{description}</DialogContentText>
        ) : typeof htmlcomp === 'function' ? (
          htmlcomp()
        ) : (
          htmlcomp
        )}
      </DialogContent>
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button
              variant="contained"
              onClick={() => {
                onSubmit();
                resolve(); // Resolve the promise on submit
              }}
            >
              Yes, I agree
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onClose();
                reject(); // Reject the promise on cancel
              }}
              autoFocus
            >
              CANCEL
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button
            color="primary"
            onClick={() => {
              onSubmit();
              resolve(); // Resolve the promise for info variant
            }}
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
