import MuiAlert from '@mui/material/Alert';
import Notification from 'rc-notification';
import 'rc-notification/assets/index.css';
import { forwardRef } from 'react';

const Alert = forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const options = {
  error: {
    option: 'error'
  },
  info: {
    option: 'info'
  },
  success: {
    option: 'success'
  },
  warning: {
    option: 'warning'
  }
};

type AlertProps = {
  message: string;
  title: string;
  type: any;
};

let notification: any = null;
let notificationApi: any = {};

Notification.newInstance(
  {
    className: 'notification',
    style: {
      right: 24,
      top: 24,
      bottom: 'auto',
      padding: 0,
      zIndex: 9999,
      width: '95%'
    }
  },
  (n) => (notification = n)
);

const notify = ({ message, title, type, ...rest }: AlertProps) => {
  const { option } = options[type as keyof typeof options] || {};
  notification.notice({
    content: <Alert severity={option}>{message}</Alert>,
    duration: type == 'warning' ? 3 : 2,
    closable: true,
    ...rest
  });
};

Object.keys(options).forEach((type) => {
  notificationApi[type] = (args: any) =>
    notify({
      ...args,
      type
    });
});

export default notificationApi;
