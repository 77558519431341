import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../redux/store';
import React, { useState } from 'react';
import moment from 'moment-timezone';

const apiLink =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080/api/'
    : 'https://gmsapi.bluehms.com/api/';

const GlobalContext = React.createContext({});
export const GlobalProvider = GlobalContext.Provider;
export default GlobalContext;

export const logoPath =
  'https://bluetechstore.blob.core.windows.net/bluehms/logo/';

export const btBaseQuery = fetchBaseQuery({
  baseUrl: apiLink,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).gdata.token;

    if (token) {
      headers.set('auth', `${token}`);
    }
    return headers;
  }
});

export const storeToken = (jwtToken: string) => {
  try {
    localStorage.setItem('token', jwtToken);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveToken = () => {
  try {
    const value = localStorage.getItem('token');
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const storeSetting = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveSetting = (name: string) => {
  try {
    const value = localStorage.getItem(name);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const removeSetting = (name: string) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (error) {
    return error;
  }
};

export const modeType = [
  { id: '0', label: 'Credit' },
  { id: '1', label: 'Cash' },
  { id: '2', label: 'Card' },
  { id: '3', label: 'Cheque' },
  { id: '4', label: 'Bank Transfer' },
  { id: '5', label: 'UPI' }
];

export const useAsyncState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value: any) => {
    return new Promise((resolve) => {
      setState(value);
      setState((current: any) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const toDate = (utcDt: any) => {
  var localTime = moment.utc(utcDt).format();
  return moment.utc(localTime).local();
};
