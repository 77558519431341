import { useRoutes } from 'react-router-dom';
import routes, { authroutes } from './router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ConfirmationServiceProvider } from './components/Dialog/ConfirmationService';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import BTDialogProvider from 'src/components/BTDialog';

function App() {
  const gdata = useSelector((state: RootState) => state.gdata);
  const auth = useRoutes(authroutes);
  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmationServiceProvider>
          <BTDialogProvider>
            <CssBaseline />
            {gdata.user && gdata.user !== '' ? content : auth}
          </BTDialogProvider>
        </ConfirmationServiceProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
