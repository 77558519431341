import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import { companyApi } from '../services/acompanyApi';
import { countryApi } from '../services/acountryApi';
import { stateApi } from '../services/astateApi';
import { usersApi } from '../services/ausersApi';
import { authSlice } from '../services/authSlice';

import { modeApi } from '../services/amodeApi';
import { taxgroupApi } from '../services/ataxgroupApi';
import { taxApi } from '../services/ataxApi';
import { uomApi } from '../services/auomApi';

import { dashboardApi } from '../services/dashboardApi';

import { acsettingApi } from '../services/acasettingApi';
import { aceexpensesApi } from '../services/aceexpensesApi';
import { aceincomesApi } from '../services/aceincomesApi';
import { acmheadApi } from '../services/acmheadApi';
import { acreportsApi } from '../services/acreportsApi';

import { emmdepartmentApi } from '../services/emmdepartmentApi';
import { emmdesignationApi } from '../services/emmdesignationApi';
import { emmemployeeApi } from '../services/emmemployeeApi';
import { emeattendanceApi } from '../services/emeattendanceApi';
import { emepayrollApi } from '../services/emepayrollApi';
import { emewagesApi } from '../services/emewagesApi';
import { emreportsApi } from '../services/emreportsApi';

import { stsettingApi } from '../services/stasettingApi';
import { stmsupplierApi } from '../services/stmsupplierApi';
import { stmgroupApi } from '../services/stmgroupApi';
import { stmproductApi } from '../services/stmproductApi';
import { stepurchaseApi } from '../services/stepurchaseApi';
import { steissueApi } from '../services/steissueApi';
import { streportsApi } from '../services/streportsApi';

import { ormcustomerApi } from '../services/ormcustomerApi';
import { ormgarmenttypeApi } from '../services/ormgarmenttypeApi';
import { ormPatternApi } from '../services/ormPatternApi';
import { ormConsumableApi } from '../services/ormConsumableApi';

import { oreOrderApi } from '../services/oreOrderApi';
import { oreCuttingApi } from '../services/oreCuttingApi';
import { oreStitchingApi } from '../services/oreStitchingApi';
import { orePackingApi } from '../services/orePackingApi';
import { orReportsApi } from '../services/orReportsApi';

import notification from '../../components/Notification';
import { logicSlice } from '../services/agen';
import Logout from 'src/screens/auth/Logout';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.originalStatus) {
        case 500: {
          notification.error({
            title: 'Server Error',
            message: action.payload.data
          });
          break;
        }
        case 400: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 401: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          console.log('LogOut');
          Logout();
          break;
        }
        case 300: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 2001: {
          notification.success({
            title: 'Sucess',
            message: action.payload.data
          });
          break;
        }
      }
    }

    return next(action);
  };

const rootReducer = combineReducers({
  [usersApi.reducerPath]: usersApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [stateApi.reducerPath]: stateApi.reducer,
  [modeApi.reducerPath]: modeApi.reducer,
  [taxgroupApi.reducerPath]: taxgroupApi.reducer,
  [taxApi.reducerPath]: taxApi.reducer,
  [uomApi.reducerPath]: uomApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [acsettingApi.reducerPath]: acsettingApi.reducer,
  [acmheadApi.reducerPath]: acmheadApi.reducer,
  [aceexpensesApi.reducerPath]: aceexpensesApi.reducer,
  [aceincomesApi.reducerPath]: aceincomesApi.reducer,
  [acreportsApi.reducerPath]: acreportsApi.reducer,
  [emmdepartmentApi.reducerPath]: emmdepartmentApi.reducer,
  [emmdesignationApi.reducerPath]: emmdesignationApi.reducer,
  [emmemployeeApi.reducerPath]: emmemployeeApi.reducer,
  [emeattendanceApi.reducerPath]: emeattendanceApi.reducer,
  [emepayrollApi.reducerPath]: emepayrollApi.reducer,
  [emewagesApi.reducerPath]: emewagesApi.reducer,
  [emreportsApi.reducerPath]: emreportsApi.reducer,
  [stsettingApi.reducerPath]: stsettingApi.reducer,
  [stmsupplierApi.reducerPath]: stmsupplierApi.reducer,
  [stmgroupApi.reducerPath]: stmgroupApi.reducer,
  [stmproductApi.reducerPath]: stmproductApi.reducer,
  [stepurchaseApi.reducerPath]: stepurchaseApi.reducer,
  [steissueApi.reducerPath]: steissueApi.reducer,
  [streportsApi.reducerPath]: streportsApi.reducer,
  [ormcustomerApi.reducerPath]: ormcustomerApi.reducer,
  [ormgarmenttypeApi.reducerPath]: ormgarmenttypeApi.reducer,
  [ormPatternApi.reducerPath]: ormPatternApi.reducer,
  [ormConsumableApi.reducerPath]: ormConsumableApi.reducer,
  [oreOrderApi.reducerPath]: oreOrderApi.reducer,
  [oreCuttingApi.reducerPath]: oreCuttingApi.reducer,
  [oreStitchingApi.reducerPath]: oreStitchingApi.reducer,
  [orePackingApi.reducerPath]: orePackingApi.reducer,
  [orReportsApi.reducerPath]: orReportsApi.reducer,

  gdata: authSlice.reducer,
  glogic: logicSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddiware) =>
    getDefaultMiddiware().concat(
      rtkQueryErrorLogger,
      usersApi.middleware,
      companyApi.middleware,
      countryApi.middleware,
      stateApi.middleware,
      modeApi.middleware,
      taxgroupApi.middleware,
      taxApi.middleware,
      uomApi.middleware,
      dashboardApi.middleware,
      acsettingApi.middleware,
      acmheadApi.middleware,
      emmdepartmentApi.middleware,
      emmdesignationApi.middleware,
      emmemployeeApi.middleware,
      emeattendanceApi.middleware,
      emepayrollApi.middleware,
      emewagesApi.middleware,
      emreportsApi.middleware,
      aceexpensesApi.middleware,
      aceincomesApi.middleware,
      acreportsApi.middleware,
      stsettingApi.middleware,
      stmsupplierApi.middleware,
      stmgroupApi.middleware,
      stmproductApi.middleware,
      stepurchaseApi.middleware,
      steissueApi.middleware,
      streportsApi.middleware,
      emmemployeeApi.middleware,
      ormcustomerApi.middleware,
      ormgarmenttypeApi.middleware,
      ormPatternApi.middleware,
      ormConsumableApi.middleware,
      oreOrderApi.middleware,
      oreCuttingApi.middleware,
      oreStitchingApi.middleware,
      orePackingApi.middleware,
      orReportsApi.middleware
    ),
  devTools: true
});

export default store;
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
